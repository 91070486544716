<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="flex flex-col space-y-6" @submit.prevent="submitForm()">
      <div class="rounded-lg overflow-hidden relative h-40">
        <div
          class="
            cursor-pointer
            absolute
            z-20
            flex flex-col
            items-center
            justify-center
            w-full
            h-full
            bg-gray-300 bg-opacity-30
          "
          @click="openImageUploader()"
        >
          <IconComponent icon="charm:upload" />
          <p class="small bold select-none">Upload thumbnail</p>
        </div>
        <input
          @change="uploadImage($event)"
          type="file"
          class="hidden"
          name="book-image"
          id="book-image"
          accept="image/*"
        />
        <img
          class="w-full h-full object-cover object-center"
          v-if="displayImage"
          :src="displayImage"
        />
      </div>
      <div class="form md:col-span-2">
        <InputComponent :required="true" title="Name" v-model="book.name" />
        <div class="grid grid-cols-2 gap-6">
          <InputComponent
            type="number"
            :required="true"
            min="0"
            title="Price (NGN)"
            v-model="book.price"
          />
          <InputComponent
            type="number"
            :required="true"
            min="1"
            title="Quantity"
            v-model="book.quantity"
          />
        </div>
        <InputComponent :required="true" title="Author" v-model="book.author" />
        <InputComponent
          type="textarea"
          :required="true"
          title="Summary"
          v-model="book.summary"
        />
        <div class="flex items-center justify-between space-x-4">
          <ButtonComponent
            v-if="data"
            :ordinary="true"
            type="error"
            @click="deleteBook()"
          >
            Delete
          </ButtonComponent>
          <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "BookModal",
};
</script>

<script setup>
import { useStore } from "vuex";
import { computed, inject, ref } from "@vue/runtime-core";
import { CreateBookDTO, UpdateBookDTO } from "@/models/dtos/book-dto";
import Book from "@/models/book";
import notificationService from "@/services/notification.service";
import { PaginationOptionsDTO } from '@/models/dtos/pagination-options-dto';

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Book,
    required: false,
  },
});

const store = useStore();
const openModal = inject("openModal");
// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data
    ? new UpdateBookDTO(props.data)
    : CreateBookDTO.prototype.createEmpty();
});

const book = ref(propData.value);
const displayImage = ref(book.value.image);
const uploadedImage = ref();

const submitForm = async () => {
  const formData = new FormData();
  uploadedImage.value ? formData.append("image", uploadedImage.value) : null;
  delete book.value.image;

  for (const data in book.value) {
    formData.append(data, book.value[data]);
  }

  if (!props.data) {
    if (!uploadedImage.value) {
      return notificationService.warning(
        "Please upload an image to create this book"
      );
    }
    const message = await store.dispatch("books/create", formData);
    notificationService.success(message);
  } else {
    const message = await store.dispatch("books/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data
    ? { title: "Update book details", button: "Update" }
    : { title: "Create a new book", button: "Add Book" };
});

const openImageUploader = () => {
  const image = document.getElementById("book-image");
  image.click();
};
const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  uploadedImage.value = image;
};

const getBooks = async () => {
  await store.dispatch("books/getAll", new PaginationOptionsDTO({ limit: 12 }));
};

const deleteBook = () => {
  emit("close");
  openModal("delete", { type: "book", id: book.value.id }, "medium", getBooks);
};

// eslint-disable-next-line
defineExpose({
  text,
  book,
  displayImage,
  openImageUploader,
  uploadImage,
  submitForm,
});
</script>
