import Subscriber from "./subscriber";

export class Analytics {
  constructor(analytics) {
    this.revenue = analytics.revenue || 0;
    this.channels = analytics.channels || 0;
    this.subscriptions = analytics.subscriptions.map((subscription) => new Subscription(subscription)) || [];
    this.contentCreators = analytics.contentCreators || 0;
    this.publishedLessons = analytics.publishedLessons || 0;
    this.activeSubscriptionCount = analytics.activeSubscriptionCount || 0;
    this.subscribersCount = new SubscribersCount(analytics.subscribersCount) || null;
  }

  createEmpty() {
    return new Analytics({ revenue: 0, channels: 0, subscriptions: [] });
  }
}

class SubscribersCount {
  constructor(subscribersCount) {
    if (subscribersCount) {
      this.active = subscribersCount.active || []
      this.inactive = subscribersCount.inactive || []
    }
  }
}

class Subscription {
  constructor(subscription) {
    this.id = subscription._id;
    this.amount = subscription.amount;
    this.autoRenew = subscription.autoRenew;
    this.subscriber = new Subscriber(subscription.subscriber);
    this.createdAt = subscription.createdAt;
    this.updatedAt = subscription.updatedAt;
  }

  get subscriptionDate() {
    return new Date(this.updatedAt).toLocaleDateString()
  }
}