<template>
  <div
    class="
      h-screen
      w-screen
      overflow-hidden
      flex flex-col
      items-center
      justify-center
      space-y-6
      bg-gray-300
      p-8
    "
  >
    <img :src="logo" alt="Logo" class="w-20 h-20" />
    <router-view v-slot="{ Component }">
      <component :is="Component"></component>
    </router-view>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "DefaultLayout",
  setup() {
    const store = useStore();
    const logo = computed(() => {
      return store.getters.organizationLogo;
    });

    return { logo };
  },
});
</script>