import Channel from "./channel";

export class User {
  constructor(user) {
    this.id = user._id;
    this.email = user.email;
    this.type = user.type;
    this.status = user.status;
    this.image = user.image;
    this.info = new Info(user.info) || null;
    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
  }

  get firstName() {
    return this.info?.name?.first || ""
  }

  get lastName() {
    return this.info?.name?.last || ""
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get name() {
    return this.info.name
  }

  get phone() {
    return this.info.phone
  }

  get role() {
    return this.info?.role;
  }
}

export class Info {
  constructor(info) {
    this.name = info.name;
    this.phone = info.phone;
    this.role = info.role;
    this.channels = info.channels ? info.channels.map((channel) => new Channel(channel)) : null;
  }
}

export const Role = Object.freeze({
  administrator: 'administrator',
  manager: 'manager',
  contentCreator: 'content-creator',
  contentEditor: 'content-editor'
})