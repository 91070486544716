import currencyRenderer from "@/providers/currencyRenderer";
import { Address } from "./address";
import Book from "./book";
import Subscriber from "./subscriber";

export default class Order {
  constructor(order) {
    this.id = order._id
    this.subscriber = new Subscriber(order.subscriber);
    this.cart = new Cart(order.cart);
    this.totalPrice = order.totalPrice;
    this.status = order.status;
    this.shippingAddress = new Address(order.shippingAddress);
    this.createdAt = order.createdAt;
    this.updatedAt = order.updatedAt;
  }

  get orderID() {
    return this.id.slice(-8).toUpperCase()
  }

  get book() {
    return this.cart.items[0].book.name
  }

  get customer() {
    return this.subscriber.fullName
  }

  get formattedPrice() {
    if (Number(this.totalPrice) === 0) return 'FREE';
    return currencyRenderer(this.totalPrice);
  }

  get date() {
    return new Date(this.createdAt).toDateString()
  }
}

class Cart {
  constructor(cart) {
    this.id = cart._id;
    this.items = cart.items.map((item) => new CartItem(item));
    this.status = cart.status;
    this.createdAt = cart.createdAt;
    this.updatedAt = cart.updatedAt;
  }

  get size() {
    const total = this.items.reduce((acc, item) => acc + item.quantity, 0)
    return `${total} book${total == 1 ? "" : "s"}`
  }

  get formattedTotalPrice() {
    const total = this.items.reduce((acc, item) => acc + item.price, 0)
    return currencyRenderer(total);
  }
}

class CartItem {
  constructor(item) {
    this.id = item._id;
    this.book = new Book(item.book);
    this.price = item.price;
    this.quantity = item.quantity;
  }

  get formattedPrice() {
    if (Number(this.price) === 0) return 'FREE';
    return currencyRenderer(this.price / this.quantity);
  }
}

export const OrderStatuses = Object.freeze({
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
})