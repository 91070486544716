<template>
  <div class="">
    <h4>{{ text.title }}</h4>
    <form class="grid md:grid-cols-3 gap-6" @submit.prevent="submitForm()">
      <div class="md:col-span-1 rounded-lg overflow-hidden relative">
        <div
          class="
            cursor-pointer
            absolute
            z-20
            flex flex-col
            items-center
            justify-center
            w-full
            h-full
            bg-gray-300 bg-opacity-30
          "
          @click="openImageUploader()"
        >
          <IconComponent icon="charm:upload" />
          <p class="small bold select-none">Upload thumbnail</p>
        </div>
        <input
          @change="uploadImage($event)"
          type="file"
          class="hidden"
          name="magazine-image"
          id="magazine-image"
          accept="image/*"
        />
        <img
          class="w-full h-full object-cover object-center"
          v-if="displayImage"
          :src="displayImage"
        />
      </div>
      <div class="form md:col-span-2">
        <InputComponent :required="true" title="Name" v-model="magazine.name" />
        <InputComponent
          type="textarea"
          :required="true"
          title="Description"
          v-model="magazine.description"
        />
        <div class="flex items-center justify-between space-x-4">
          <ButtonComponent
            v-if="data"
            :ordinary="true"
            type="error"
            @click="deleteMagazine()"
          >
            Delete
          </ButtonComponent>
          <ButtonComponent :fullWidth="true">{{ text.button }}</ButtonComponent>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    name: 'MagazineModal',
  }
</script>

<script setup>
import { useStore } from "vuex";
import { computed, inject, ref } from "@vue/runtime-core";
import { CreateMagazineDTO, UpdateMagazineDTO } from "@/models/dtos/magazine-dto";
import Magazine from "@/models/magazine";
import notificationService from "@/services/notification.service";
import { useRouter } from "vue-router";

// eslint-disable-next-line
const props = defineProps({
  data: {
    type: Magazine,
    required: false,
  },
});

const store = useStore();
const router = useRouter();
const openModal = inject("openModal");
// eslint-disable-next-line
const emit = defineEmits(["close", "onClose", "closeWithFunction"]);

const propData = computed(() => {
  return props.data
    ? new UpdateMagazineDTO(props.data)
    : CreateMagazineDTO.prototype.createEmpty();
});

const magazine = ref(propData.value);
const displayImage = ref(magazine.value.image);
const uploadedImage = ref();

const submitForm = async () => {
  const formData = new FormData();
  uploadedImage.value ? formData.append("image", uploadedImage.value) : null;
  delete magazine.value.image;

  for (const data in magazine.value) {
    formData.append(data, magazine.value[data]);
  }

  if (!props.data) {
    if (!uploadedImage.value) {
      return notificationService.warning(
        "Please upload an image to create this magazine"
      );
    }
    const message = await store.dispatch("magazines/create", formData);
    notificationService.success(message);
  } else {
    const message = await store.dispatch("magazines/update", formData);
    notificationService.success(message);
  }
  await emit("closeWithFunction");
};

const text = computed(() => {
  return props.data
    ? { title: "Update magazine details", button: "Update" }
    : { title: "Create a new magazine", button: "Create Magazine" };
});

const openImageUploader = () => {
  const image = document.getElementById("magazine-image");
  image.click();
};
const uploadImage = ($event) => {
  const image = $event.target.files[0];
  displayImage.value = URL.createObjectURL(image);
  uploadedImage.value = image;
};

const goToMagazines = () => {
  router.push({ name: "magazines" });
};

const deleteMagazine = () => {
  emit("close");
  openModal(
    "delete",
    { type: "magazine", id: magazine.value.id },
    "medium",
    goToMagazines
  );
};

// eslint-disable-next-line
defineExpose({
  text,
  magazine,
  displayImage,
  openImageUploader,
  uploadImage,
  submitForm,
});
</script>
